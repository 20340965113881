import ItemContact from "../../components/ItemContact";
import {
    faPhone,
    faMapLocation,
    faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../../components/ContactForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Contact = () => {
    return (
        <div className="contact-parent-container">
            <p id="title-contact-page">Me contacter</p>
            <div className={"container-contact"}>
                <section className={"items-section"}>
                    <div className={"item-contact"}>
                        <div className={"item-contact--icon"}>
                            <FontAwesomeIcon icon={faEnvelope} color={"white"} />
                        </div>
                        <a className={"item-contact--content"} href="mailto:hugo.delahaye53@outlook.fr">hugo.delahaye53@outlook.fr</a>
                    </div>
                    <div className={"item-contact"}>
                        <div className={"item-contact--icon"}>
                            <FontAwesomeIcon icon={faPhone} color={"white"} />
                        </div>
                        <a className={"item-contact--content"} href="tel:+33768960003">07 68 96 00 03</a>
                    </div>
                    <ItemContact
                        icon={faMapLocation}
                        content={"Département de la Mayenne, France"}
                    />
                </section>
                <section className={"contact-me-section"}>
                    <ContactForm />
                </section>
            </div>
        </div>
    );
};

export default Contact;
