// LoadingSpinner.js
import React from 'react';
//import './LoadingSpinner.css'; // Importer les styles CSS

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner">
      <img 
        src={"/assets/images/avatar.png"}
        alt="spinner"
        className="loading-logo" 
        />
    </div>
  );
};

export default LoadingSpinner;
