import { Link } from "react-router-dom";
import { projects } from "../../routes/routes";
import { useState } from "react";
import { InfinitySpin } from "react-loader-spinner";

const ProjectCard = ({ img, title, projectNumber, id }) => {
    const [isLoading, setIsLoading] = useState(true);

    return (
        <Link
            to={projects + `/${projectNumber}`}
            className={"project-card"}
            id={`project-card-${id}`}
        >
            {isLoading ? (
                <div className="project-card--loader-logo-container loader-logo-container" style={{height: '100%'}}>
                    <InfinitySpin width="200" color="#00B2A1" />
                </div>
            ) : <>
                <div className="project-card--img-box">
                    <img src={img} alt="logo projet" onLoad={() => setIsLoading(false)} />
                </div>
                <div className="project-card--details">
                    <p className={"title-project"}>{title}</p>
                </div>
            </>
            }
            <img src={img} alt="logo projet" onLoad={() => setIsLoading(false)} style={{display: 'none'}} />
        </Link>
    );
};
export default ProjectCard;
