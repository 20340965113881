import LoaderProject3 from "../LoaderProject3";
import { Calendar } from "@hugo.delahaye53/react-datepicker";
import "@hugo.delahaye53/react-datepicker/dist/esm/styles/style.css";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { InfinitySpin } from "react-loader-spinner";
import { useEffect } from "react";

const ProjectContent = ({ activeProject }) => {
    const [, setDate] = useState();
    const [isLoading, setIsLoading] = useState(true);

    // screenshotMobile
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth >= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={"active-project"}>
            <p className={"active-project--title"}>{activeProject.title}</p>
            {isWideScreen ? <>
                {activeProject.screenshot !== undefined ? (
                    <div className={"active-project--image-container image-container"}>
                        <img
                            className={"image-container--screenshot"}
                            src={activeProject.screenshot}
                            alt="aperçu du projet"
                            onLoad={() => setIsLoading(false)}
                        />

                        {isLoading === true ? (
                            <div className="image-container--loader-container loader-container">
                                <InfinitySpin width="200" color="#00B2A1" />
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                ) : (
                    ""
                )}
            </>
                :
                <>
                    {activeProject.screenshotMobile !== undefined ? (
                        <div className={"active-project--image-container image-container"}>
                            <img
                                className={"image-container--screenshot"}
                                src={activeProject.screenshotMobile}
                                alt="aperçu du projet"
                                onLoad={() => setIsLoading(false)}
                            />

                            {isLoading === true ? (
                                <div className="image-container--loader-container loader-container">
                                    <InfinitySpin width="200" color="#00B2A1" />
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    ) : (
                        ""
                    )}
                </>
            }

            <div className={"active-project--container-links"}>
                {activeProject.demo !== undefined ? (
                    <div>
                        <a
                            className={"active-project--link--item"}
                            href={activeProject.demo}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Démo du projet
                        </a>
                    </div>
                ) : (
                    ""
                )}
                {activeProject.github !== undefined ? (
                    <div>
                        <a
                            className={"active-project--link--item"}
                            href={activeProject.github}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Lien GitHub du projet
                        </a>
                    </div>
                ) : (
                    ""
                )}
            </div>
            <div className={"active-project--technologies-list"}>
                <p>Technologies utilisées</p>
                <ul>
                    {activeProject.technologies.map((item, key) => (
                        <li key={key}>{item}</li>
                    ))}
                </ul>
            </div>
            <div className={"active-project--project-description"}>
                <p className={"active-project--project-description--title"}>
                    Description
                </p>
                <p>{activeProject.description}</p>
            </div>
            {activeProject.id === 7 ? <LoaderProject3 /> : ""}
            {activeProject.achievements !== undefined ? (
                <div className={"active-project--achievements-list"}>
                    <p>Compétences acquises</p>
                    <ul>
                        {activeProject.achievements.map((item, key) => (
                            <li key={key}>{item}</li>
                        ))}
                    </ul>
                </div>
            ) : (
                ""
            )}

            {activeProject.documents !== undefined ? (
                <div className={"active-project--documents"}>
                    <p className={"active-project--documents--title"}>
                        Documents annexes
                    </p>
                    {activeProject.documents.map((item, key) => (
                        <div key={key}>
                            <FontAwesomeIcon icon={faLink} color="white" />
                            <a
                                href={item.linkDocument}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {item.title}
                            </a>
                        </div>
                    ))}
                </div>
            ) : (
                ""
            )}
            {activeProject.id === 11 ? (
                <div className="calendar-container">
                    <p id="calendar-container--title">Démo calendrier</p>
                    <Calendar
                        languageChoice={"fr"}
                        yearMin={1960}
                        yearMax={new Date().getFullYear() + 20}
                        returnFormat={"dd/MM/yyyy"}
                        defaultDate={new Date(2001, 6, 31)}
                        labelContent={"Date de naissance"}
                        handleDateChanged={(date) => setDate(date)}
                        classChange={"demo-calendar"}
                    />
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

export default ProjectContent;
