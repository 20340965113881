import React, { useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import emailjs from "@emailjs/browser";
import { toast } from 'react-toastify';

const ContactForm = () => {
  const form = useRef();

  const contactSchema = Yup.object().shape({
    form_name: Yup.string().required("Nom requis"),
    form_email: Yup.string().email("Email invalide").required("Email requis"),
    message: Yup.string().required("Message requis"),
  });

  const formik = useFormik({
    initialValues: {
      form_name: "",
      form_email: "",
      message: "",
    },
    validationSchema: contactSchema,
    onSubmit: (values, { resetForm }) => {
      emailjs
        .send(
          process.env.REACT_APP_serviceIdEmailJS,
          process.env.REACT_APP_templateIdEmailJS,
          {
            from_name: values.form_name,
            from_email: values.form_email,
            message: values.message
          },
          process.env.REACT_APP_publicKeyEmailJS
        )
        .then((response) => {
          toast.success("Message envoyé, merci !");
        })
        .catch((error) => {
          console.error('Erreur lors de l\'envoi de l\'e-mail:', error);
        });
      resetForm();
    },
  });

  return (
    <div>
      <form ref={form} onSubmit={formik.handleSubmit}>
        <label htmlFor="form_name">Nom</label>
        <input
          id="form_name"
          name="form_name"
          placeholder="Steve Dupont"
          onChange={formik.handleChange}
          value={formik.values.form_name}
        />
        {formik.errors.form_name && formik.touched.form_name ? (
          <p className={"error-form"}>{formik.errors.form_name}</p>
        ) : null}

        <label htmlFor="form_email">Email</label>
        <input
          id="form_email"
          name="form_email"
          placeholder="steve.dupont@gmail.com"
          onChange={formik.handleChange}
          value={formik.values.form_email}
        />
        {formik.errors.form_email && formik.touched.form_email ? (
          <p className={"error-form"}>{formik.errors.form_email}</p>
        ) : null}

        <label htmlFor="message">Message</label>
        <textarea
          id="message"
          name="message"
          placeholder="Mon message..."
          onChange={formik.handleChange}
          value={formik.values.message}
        />
        {formik.errors.message && formik.touched.message ? (
          <p className={"error-form"}>{formik.errors.message}</p>
        ) : null}

        <button type="submit" id="submit-button">
          Envoyer
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
