const listOfPersoProjects = [
    {
        title: "Canal React",
        img: "/assets/images/projects/canal-react.png",
        id: 19,
        screenshot: "/assets/images/projects-preview/preview-canal-react.png",
        screenshotMobile: "/assets/images/projects/canal-react.png",
        description:
            "Canal React est un projet personnel inspiré du site MyCanal. Différentes fonctionnalités sont disponibles comme obtenir les séries/films du jour ainsi que les séries et films du moment affichés sur la page d'accueil. Vous pouvez également venir rechercher un film, une série ou un acteur. Pour obtenir ces différentes informations, j'ai utilisé l'API The Movie Database. React a été utilisé pour cette application.",
        technologies: [
            "HTML5",
            "CSS3",
            "JavaScript",
            "React",
            "React-Router",
            "React Context",
            "npm",
            "API The Movie Database",
        ],
        github: "https://github.com/HugoDlhOC/canal-react",
        demo: "https://snazzy-pavlova-96e878.netlify.app",
        achievements: undefined,
        documents: undefined,
    },
    {
        title: "Bootstrap Portfolio",
        img: "/assets/images/projects/bootstrap-portfolio.png",
        id: 20,
        screenshot:
            "/assets/images/projects-preview/preview-bootstrap-portfolio.png",
        screenshotMobile: "/assets/images/projects/bootstrap-portfolio.png",
        description:
            "Un projet personnel permettant d'utiliser le framework CSS Bootstap ici sous forme de portfolio à titre d'exemple. De nombreux composants sont utilisés comme le carousel, barres de progression, modal.",
        technologies: ["HTML5", "CSS3", "JavaScript", "Bootstrap"],
        github: "https://github.com/HugoDlhOC/portfolio-bootstrap-d-hugo",
        demo: "https://hugodlhoc.github.io/portfolio-bootstrap-d-hugo/",
        achievements: undefined,
        documents: undefined,
    },
    {
        title: "Front-End Cheat Sheet",
        img: "/assets/images/projects/cheat-sheet.png",
        id: 21,
        screenshot: "/assets/images/projects-preview/preview-cheat-sheet.png",
        screenshotMobile: "/assets/images/projects/cheat-sheet.png",
        description:
            "Un projet personnel ayant pour but de regrouper des ressources en développement front-end qui sont très utiles au quotidien.",
        technologies: ["HTML5", "CSS3", "JavaScript", "ReactJS"],
        github: "https://github.com/HugoDlhOC/cheat-sheet-front",
        demo: "https://front-end-cheat-sheet-hugo-d.netlify.app/",
        achievements: undefined,
        documents: undefined,
    },
    {
        title: "React Native Tesla Clone",
        img: "/assets/images/projects/tesla-clone.png",
        id: 22,
        screenshot: "/assets/images/projects-preview/preview-tesla-clone.png",
        screenshotMobile: "/assets/images/projects/tesla-clone.png",
        description:
            "Un projet personnel d'initiation à React Native montrant une reproduction simple de la page d'accueil de Tesla en application React Native avec 4 voitures présentées.",
        technologies: ["React Native", "Expo", "JavaScript"],
        github: "https://github.com/HugoDlhOC/TeslaCloneApp",
        demo: undefined,
        achievements: undefined,
        documents: undefined,
    },
];

export default listOfPersoProjects;
