const LoaderProject3 = () => {
  return (
    <div className="loader-p2-container">
      <p className={"loader--title"}>Loader</p>
      <div className="loader">
        <div className="chargement">
          <div className="chargement__balle haut"></div>
          <div className="chargement__balle droite"></div>
          <div className="chargement__balle bas"></div>
          <div className="chargement__balle gauche"></div>
        </div>
      </div>
    </div>
  );
};

export default LoaderProject3;
