const LegalTerms = () => {
    return (
        <div className="legal_terms_container" id="legal_terms_container_id">
            <div className="legal_terms_container bloc_terms">
                <h3 className="bloc_terms_title" id="first_title_bloc_terms">
                    ÉDITEUR DU SITE
                </h3>
                <p className="bloc_terms_item">
                    Le site https://hugodelahaye.com est édité par Hugo Delahaye.
                </p>
                <p className="bloc_terms_item">Téléphone : <a className="bloc_terms_item" href="tel:+33768960003">07 68 96 00 03</a></p>
                <p className="bloc_terms_item">E-mail : <a className="bloc_terms_item" href="mailto:hugo.delahaye53@outlook.fr">hugo.delahaye53@outlook.fr</a></p>
            </div>
            <div className="legal_terms_container bloc_terms">
                <h3 className="bloc_terms_title">HÉBERGEMENT DU SITE</h3>
                <p className="bloc_terms_item">Service Google Firebase par Google</p>
                <p className="bloc_terms_item">
                    Siège social : 8 rue de Londres – 75009 Paris
                </p>
                <p className="bloc_terms_item">
                    Contact : https://firebase.google.com/support/troubleshooter/contact
                </p>
                <p className="bloc_terms_item">Téléphone : <a className="bloc_terms_item" href="tel:+33142685300">01 42 68 53 00</a></p>
            </div>
            <div className="legal_terms_container bloc_terms">
                <h3 className="bloc_terms_title">
                    Collecte et utilisation de vos données personnelles
                </h3>
                <h4 className="bloc_terms_title">Types de données collectées</h4>
                <h4 className="bloc_terms_title">Données personnelles</h4>
                <p className="bloc_terms_item">
                    Lors de l'utilisation de notre service, nous pouvons vous demander de
                    nous fournir certaines informations personnellement identifiables qui
                    peuvent être utilisées pour vous contacter ou vous identifier. Les
                    informations personnellement identifiables peuvent inclure, mais sans
                    s'y limiter :
                </p>
                <ul className="bloc_terms_list">
                    <li>Adresse e-mail</li>
                    <li>Prénom et nom</li>
                </ul>
            </div>
        </div>
    );
};

export default LegalTerms;
