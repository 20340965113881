import { useNavigate, useParams } from "react-router-dom";
import { errorPage } from "../../routes/routes";
import { useEffect, useState } from "react";
import ProjectContent from "../../components/ProjectContent";
import listOfProProjects from "../../data/listOfProProjects";
import listOfOcProjects from "../../data/listOfOcProjects";
import listOfPersoProjects from "../../data/listOfPersoProjects";
import listOfUdemyProjects from "../../data/listOfUdemyProjects";

const Project = () => {
    const navigate = useNavigate();
    const [activeProjectState, setActiveProjectState] = useState(null);
    const { projectNumber } = useParams();
    let activeProject = listOfOcProjects.find(
        (project) => project.id === parseInt(projectNumber)
    );

    if (activeProject === undefined) {
        //the project is not a OC project, but can be a perso project

        activeProject = listOfPersoProjects.find(
            (project) => project.id === parseInt(projectNumber)
        );

        if (activeProject === undefined) {
            activeProject = listOfProProjects.find(
                (project) => project.id === parseInt(projectNumber)
            );
        }

        if (activeProject === undefined) {
            activeProject = listOfUdemyProjects.find(
                (project) => project.id === parseInt(projectNumber)
            );
        }
    }

    useEffect(() => {
        if (!activeProject) {
            navigate(errorPage);
        } else {
            setActiveProjectState(activeProject);
        }
    }, [navigate, projectNumber]);

    return (
        <div>
            {activeProjectState && <ProjectContent activeProject={activeProject} />}
        </div>
    );
};

export default Project;
