const listOfProProjects = [
    {
        title: "Création d'un site web avec React JS et Gatsby",
        img: "/assets/images/projects/project-pro1.png",
        id: 1,
        screenshot: "/assets/images/projects/project-pro1.png",
        screenshotMobile: "/assets/images/projects/project-pro1.png",
        description:
            "J’ai collaboré sur ce projet avec les technologies React JS et Gatsby au sein de l’entreprise ITKEEP pour un client dans le domaine du VTC. Le projet consistait à développer une application web moderne en m’appuyant sur des maquettes fournies. Mes responsabilités dans ce projet étaient de créer l’architecture Front-End avec React JS, de créer les différentes pages, les différents composants, la connexion avec une API pour la récupération de données. J’ai dû créer un formulaire à 5 étapes de remplissage de données pour la réservation d’un trajet, gérer le responsive pour l’adaptation sur les différentes tailles d’écran et gérer l’accessibilité.",
        technologies: [
            "HTML5",
            "CSS3",
            "JavaScript",
            "React JS",
            "Reach Router",
            "Gatsby",
            "npm",
            "Firebase",
            "BitBucket",
            "Visual Studio Code"
        ],
        github: undefined,
        demo: undefined,
        achievements: undefined,
        documents: undefined,
    },
    {
        title: "Intervention sur une app mobile avec React Native",
        img: "/assets/images/projects/project-pro2.png",
        id: 2,
        screenshot: "/assets/images/projects/project-pro2.png",
        screenshotMobile: "/assets/images/projects/project-pro2.png",
        description:
            "J’ai collaboré sur cette application avec React Native au sein de l’entreprise ITKEEP pour un client dans le domaine du VTC. Je suis intervenu dans ce projet pour de petits correctifs Front-End.",
        technologies: [
            "HTML5",
            "CSS3",
            "JavaScript",
            "React Native",
            "npm",
            "Visual Studio Code",
            "BitBucket"
        ],
        github: undefined,
        demo: undefined,
        achievements: undefined,
        documents: undefined,
    },
    {
        title: "Création d'un Back Office avec React JS",
        img: "/assets/images/projects/project-pro3.png",
        id: 3,
        screenshot: "/assets/images/projects/project-pro3.png",
        screenshotMobile: "/assets/images/projects/project-pro3.png",
        description:
            "J’ai collaboré sur ce projet avec React JS au sein de l’entreprise ITKEEP pour un client dans le domaine du VTC. Le projet consistait à développer une application pour les ressources humaines, complète, permettant de gérer tous les clients, avoir toutes les informations le concernant, et pouvoir gérer les commandes, etc. J’ai pu m’occuper de l’intégration des différents pages de l’application à l’aide de maquettes. J’ai également utilisé Material UI pour des éléments tels que des tableaux.",
        technologies: [
            "HTML5",
            "CSS3",
            "JavaScript",
            "React JS",
            "npm",
            "Material UI",
            "Visual Studio Code",
            "BitBucket"
        ],
        github: undefined,
        demo: undefined,
        achievements: undefined,
        documents: undefined,
    },
    {
        title: "Application web RH avec Angular et Java Spring",
        img: "/assets/images/projects/project-pro4.png",
        id: 4,
        screenshot: "/assets/images/projects/project-pro4.png",
        screenshotMobile: "/assets/images/projects/project-pro4.png",
        description:
            "J’ai apporté ma contribution à un projet d’application web destiné au département des ressources humaines d’une entreprise quand je travaillais chez ITKEEP. Mon rôle se concentrait principalement sur la résolution de problèmes côté Front-End, où j’ai identifié et résolu des problèmes pour améliorer l’expérience utilisateur. J’ai également développé une classe utilitaire personnalisée en Javascript permettant de convertir les données affichées à l’écran via le Front-End en fichiers XLSX téléchargeables, simplifiant ainsi la gestion des données pour les utilisateurs.",
        technologies: [
            "HTML5",
            "CSS3",
            "JavaScript",
            "Angular",
            "npm",
            "Angular Material",
            "Visual Studio Code",
            "Oracle"
        ],
        github: undefined,
        demo: undefined,
        achievements: undefined,
        documents: undefined,
    },
    {
        title: "Site E-commerce avec environnement Salesforce",
        img: "/assets/images/projects/project-pro5.png",
        screenshotMobile: "/assets/images/projects/project-pro5.png",
        id: 5,
        screenshot: "/assets/images/projects/project-pro5.png",
        description:
            "Lorsque je travaillais chez ITKEEP, j’ai apporté ma contribution pour un client spécialisé dans le E-commerce de produits cosmétique en utilisant l’environnement Salesforce. Mon rôle consistait à apporter des modifications côté Front-End pour une opération commerciale de la marque. De plus je suis également intervenu côté Back-End en identifiant et apportant des correctifs à différents problèmes rencontrés pour garantir la stabilité et la performance du site.",
        technologies: [
            "HTML5",
            "CSS3",
            "JavaScript",
            "Salesforce",
            "Visual Studio Code",
            "Azure DevOps",
            "Jira"
        ],
        github: undefined,
        demo: undefined,
        achievements: undefined,
        documents: undefined,
    },
];

export default listOfProProjects;
