import ProjectCard from "../../components/ProjectCard";
import listOfProProjects from "../../data/listOfProProjects";
import listOfOcProjects from "../../data/listOfOcProjects";
import listOfPersoProjects from "../../data/listOfPersoProjects";
import listOfUdemyProjects from "../../data/listOfUdemyProjects";

const MyProjects = () => {
    return (
        <main className="list-of-projects">
            <section className="list-of-projects__pro_projects">
                <h2 id="pro_projects__global_title">Projets professionnels</h2>
                <div className="pro_projects__projects_list projects_list">
                    {listOfProProjects.map((item, index) => (
                        <ProjectCard
                            title={item.title}
                            img={item.img}
                            key={index}
                            projectNumber={item.id}
                            id={index}
                        />
                    ))}
                </div>
            </section>

            <section className="list-of-projects__oc_projects">
                <h2 id="oc_projects__global_title">Projets de formation OpenClassrooms</h2>
                <div className="oc_projects__projects_list projects_list">
                    {listOfOcProjects.map((item, index) => (
                        <ProjectCard
                            title={item.title}
                            img={item.img}
                            key={index}
                            projectNumber={item.id}
                            id={index}
                        />
                    ))}
                </div>
            </section>

            <section className="list-of-projects__udemy_projects">
                <h2 id="udemy_projects__global_title">Projets de formation Udemy</h2>
                <div className="udemy_projects__projects_list projects_list">
                    {listOfUdemyProjects.map((item, index) => (
                        <ProjectCard
                            title={item.title}
                            img={item.img}
                            key={index}
                            projectNumber={item.id}
                            id={index}
                        />
                    ))}
                </div>
            </section>

            <section className="list-of-projects__perso_projects">
                <h2 id="perso_projects__global_title">Projets personnels</h2>
                <div className="perso_projects__projects_list projects_list">
                    {listOfPersoProjects.map((item, index) => (
                        <ProjectCard
                            title={item.title}
                            img={item.img}
                            key={index}
                            projectNumber={item.id}
                            id={index}
                        />
                    ))}
                </div>
            </section>
        </main>
    );
};

export default MyProjects;
