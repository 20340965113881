import { useState, useEffect } from "react";
import Presentation from "../../components/Presentation";
import LoadingSpinner from "../../components/LoadingSpinner";

const Home = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simuler un chargement de données
        setTimeout(() => {
            setIsLoading(false);
        }, 1500); // Par exemple, 3 secondes
    }, []);

    return (
        <div>
            <div className={`loading-spinner ${isLoading ? '' : 'hidden'}`}>
                <LoadingSpinner />
            </div>
            <div className={`main-content ${isLoading ? 'unseen' : 'visible'}`}>
                <>
                    <section className="presentation-section">
                        <h1 className="presentation-section--title">
                            HUGO,{" "}
                            <span className="presentation-section--title-anim">DÉVELOPPEUR</span>{" "}
                            FRONT-END
                        </h1>
                        <div className="presentation-section--scroll-down scroll-down">
                            <span id="scroll-down-first-item"></span>
                            <span id="scroll-down-second-item"></span>
                            <span id="scroll-down-third-item"></span>
                        </div>
                    </section>
                    <Presentation />
                </>
            </div>
        </div>
    );
};

export default Home;
