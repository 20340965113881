import { dataPresentation } from "../../data/textPresentation";
import Card from "../Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { InfinitySpin } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { projects } from "../../routes/routes";

const Presentation = () => {
    const [isLoading, setIsLoading] = useState(true);

    return (
        <div className="who_i_am_container" id="presentation">
            <section className="who_i_am_container__card_my_projects_section card_my_projects_section">
                <div className="my-projects-card-container">
                    <div className="my-projects-card-container--cover cover">
                        <p>Mes projets</p>
                        <div className="cover--back">
                            <Link to={projects}>Voir mes projets</Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="who_i_am_container__short_presentation_section short_presentation_section">
                {isLoading ? <InfinitySpin width="200" color="#00B2A1" /> : ""}
                <img
                    src="/assets/images/portrait.png"
                    className="short_presentation_section__image"
                    alt="photo hugo delahaye"
                    onLoad={() => setIsLoading(false)}
                />
                <div className="short_presentation_section__text_container text_container">
                    <p className="text_container__title">
                        Développeur Front-End
                    </p>
                    <p className="text_container__presentation">{dataPresentation[0]}</p>
                </div>
            </section>
            <section className="who_i_am_container__questions_section questions_section">
                <p className="questions_section__title_question">
                    Pourquoi j'ai voulu devenir Développeur Front-End ?
                </p>
                <p className="questions_section__answer_question">
                    {dataPresentation[1]}
                </p>
            </section>
            <section className="who_i_am_container__hard_skills_container hard_skills_container">
                <p className="hard_skills_container__title">
                    Mes technologies utilisées :
                </p>
                <div className="hard_skills_container__hard_skills">
                    <Card frontContent={"?"} backContent={"HTML5"} />
                    <Card frontContent={"?"} backContent={"CSS3"} />
                    <Card frontContent={"?"} backContent={"FlexBox"} />
                    <Card frontContent={"?"} backContent={"Grid"} />
                    <Card frontContent={"?"} backContent={"Bootstrap"} />
                    <Card frontContent={"?"} backContent={"SCSS"} />
                    <Card frontContent={"?"} backContent={"WordPress"} />
                    <Card frontContent={"?"} backContent={"Elementor"} />
                    <Card frontContent={"?"} backContent={"JavaScript ES6"} />
                    <Card frontContent={"?"} backContent={"ARIA"} />
                    <Card frontContent={"?"} backContent={"Jest"} />
                    <Card frontContent={"?"} backContent={"Github"} />
                    <Card frontContent={"?"} backContent={"npm"} />
                    <Card frontContent={"?"} backContent={"React Context"} />
                    <Card frontContent={"?"} backContent={"Redux"} />
                    <Card frontContent={"?"} backContent={"React JS"} />
                    <Card frontContent={"?"} backContent={"Swagger"} />
                    <Card frontContent={"?"} backContent={"Figma"} />
                </div>
            </section>
            <section className="who_i_am_container__brand_container brand_container">
                <img src="/assets/images/brand.svg" alt="logo de la marque" className="brand_logo" />
            </section>
            <div className="who_i_am_container__my_cv my_cv">
                <FontAwesomeIcon icon={faLink} color="white" className="my_cv__icon" />
                <a
                    href="https://drive.google.com/file/d/1pNtrdFCQ2PXjGPbmpzz-aWGiRJOjmPPy/view?usp=sharing"
                    className="my_cv__link"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Mon CV
                </a>
            </div>
        </div>
    );
};

export default Presentation;
