import { useEffect, useRef } from "react";

const Card = ({ backContent, frontContent, backColor }) => {
  const cardRef = useRef();
  const backFace = useRef();

  const handleShowCard = () => {
    cardRef.current.classList.toggle("is_flipped");
  };

  useEffect(() => {
    backFace.current.style.backgroundColor = backColor;
  }, []);

  return (
    <div className="flip_cards">
      <div
        className="flip_card__inner card_inner"
        ref={cardRef}
        onClick={handleShowCard}
      >
        <div className="card_inner__front_face">
          <p>{frontContent}</p>
        </div>
        <div className="card_inner__back_face back_face" ref={backFace}>
          <div className="back_face__content">
            <p>{backContent}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
