import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ItemContact = ({ icon, content }) => {
  return (
    <div className={"item-contact"}>
      <div className={"item-contact--icon"}>
        <FontAwesomeIcon icon={icon} color={"white"} />
      </div>
      <span className={"item-contact--content"}>{content}</span>
    </div>
  );
};

export default ItemContact;
