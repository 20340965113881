const listOfOcProjects = [
    {
        title: "Faire passer une librairie jQuery vers React",
        img: "/assets/images/projects/project14.png",
        id: 16,
        screenshot: "/assets/images/projects-preview/preview-p14.png",
        screenshotMobile: "/assets/images/projects/project14.png",
        description:
            "Wealth Health est une société financière qui utilise une application en interne appelée HRnet, qui gère les dossiers des employés. Cette application est ancienne et utilise du code jQuery côté front-end. Mon travail a été tout d’abord de faire passer l’ensemble du code jQuery en React. Ensuite, j’ai eu à développer un plugin disponible sur npm, et ceci tout en React. J’avais le choix entre un plugin de sélection de date, un plugin de fenêtre modale, un menu déroulant, un plugin pour les tables de données. J’ai choisi le plugin de sélection de dates. J’ai ensuite utilisé ce plugin dans l’application HRnet maintenant sous React et j’ai fait un rapport de performance entre cette version React et la version jQuery via LightHouse.",
        technologies: [
            "HTML5",
            "CSS3",
            "JavaScript",
            "React",
            "React-Router",
            "Redux",
            "React Context",
            "npm",
        ],
        github: "https://github.com/HugoDlhOC/HugoDelahaye_14_03102022",
        demo: "https://cute-sorbet-35f190.netlify.app/",
        achievements: [
            "Analyser la performance d'une application web",
            "Déployer une application front-end",
            "Programmer en JavaScript avec la programmation fonctionnelle",
            "Refondre une application pour réduire la dette technique",
        ],
        documents: undefined,
    },
    {
        title: "Développer une API pour un compte bancaire avec React",
        img: "/assets/images/projects/project13.png",
        id: 15,
        screenshot: "/assets/images/projects-preview/preview-p13.png",
        screenshotMobile: "/assets/images/projects/project13.png",
        description:
            "Argent Bank est une banque qui a besoin de mettre en place son application, qui se décompose en deux phases : une phase authentification des utilisateurs, et une phase gestion des transactions. Mon travail a été d’intégrer le site en React de manière complète. J’ai ensuite eu à gérer les appels API, la gestion de jetons JWT, l’utilisation de Redux pour gérer le state de l’ensemble de l’application. J’ai eu également à produire de la documentation d’API avec Swagger.",
        technologies: [
            "HTML5",
            "CSS3",
            "JavaScript",
            "React",
            "React-Router",
            "Redux",
            "Swagger",
        ],
        github: "https://github.com/HugoDlhOC/HugoDelahaye_13_26072022",
        achievements: [
            "Implémenter un gestionnaire d'état dans une application React",
            "Intéragir avec une API",
            "Modéliser une API",
            "S'authentifier à une API",
        ],
        documents: undefined,
    },
    {
        title: "Développez un tableau de bord d'analytics avec React",
        img: "/assets/images/projects/project12.png",
        id: 14,
        screenshot: "/assets/images/projects-preview/preview-p12.png",
        screenshotMobile: "/assets/images/projects/project12.png",
        description:
            "SportSee est une entreprise dans le domaine du coaching sportif. Elle souhaite avoir une nouvelle version de la page de profil de l’utilisateur de son site. Mon travail a été de développer le tableau de bord de la page utilisateur. J’ai eu à travailler avec axios pour gérer les appels API, j’ai utilisé une librairie externe appelée Recharts pour l’affichage des données sous forme de graphiques, et à produire de la documentation technique.",
        technologies: [
            "HTML5",
            "CSS3",
            "JavaScript",
            "React",
            "React-Router",
            "Recharts",
        ],
        github: "https://github.com/HugoDlhOC/HugoDelahaye_12_28062022",
        achievements: [
            "Développer des éléments graphiques avancés à l'aide de bibliothèques JavaScript",
            "Interagir avec un service Web",
            "Produire de la documentation technique pour une application",
            "Assurer la qualité des données d'une application",
        ],
        documents: undefined,
    },
    {
        title: "Développer une application Web avec React et React Router",
        img: "/assets/images/projects/project11.png",
        id: 13,
        screenshot: "/assets/images/projects-preview/preview-p11.png",
        screenshotMobile: "/assets/images/projects/project11.png",
        description:
            "Kasa est une entreprise fictive qui gère la location de logements entre particuliers depuis une dizaine d’années. Leur site a été codé il y a plus de 10 ans en ASP.NET, et ils souhaitent renouveler leur site en passant à une stack complète en JavaScript avec NodeJS côté Back-end et React côté Front-end. Mon travail a été de développer l’ensemble de l’application avec React.",
        technologies: [
            "HTML5",
            "CSS3",
            "JavaScript",
            "React",
            "React-Router",
            "API REST",
        ],
        github: "https://github.com/HugoDlhOC/HugoDelahaye_11_07062022",
        demo: "https://hugodlhoc.github.io/HugoDelahaye_11_07062022/",
        achievements: [
            "Créer des composants avec React",
            "Développer les routes d'une application web avec React Router",
            "Initialiser une application web avec un framework",
        ],
        documents: undefined,
    },
    {
        title: "Définissez les besoins pour une app de soutien scolaire",
        img: "/assets/images/projects/project10.png",
        id: 12,
        screenshot: "/assets/images/projects/project10.png",
        screenshotMobile: "/assets/images/projects/project10.png",
        description:
            "Learn@Home est une association fictive qui met en relation des enfants en difficulté scolaire avec des tuteurs bénévoles. Ils ont pour projet de concevoir un site web. Mes objectifs ont été de réunir/d’identifier les besoins clients, réaliser un diagramme de cas d’usage pour chacune des fonctionnalités majeures de chaque page, de définir les user stories, de réaliser des maquettes via Figma ainsi qu’un tableau Kanban découpant le projet.",
        technologies: ["Figma"],
        github: undefined,
        demo: "https://www.figma.com/file/ewVBwY4Gu1cpqB75fbHXC1/Maquettes-Learn%40Home?node-id=0%3A1&t=PcjYGWtpwnIdsPLa-1",
        achievements: [
            "Contribuer à un projet en utilisant une méthodologie agile",
            "Créer une maquette pour un client",
            "Identifier les besoins de l'application à partir d'un cahier des charges",
            "Modéliser une solution technique pour un client",
        ],
        documents: [
            {
                title: "Maquettes du site",
                linkDocument:
                    "https://drive.google.com/file/d/1Gajd61Pc1WiFb8k91LpbX-bAr62WQFW8/view?usp=sharing",
            },
            {
                title: "Liste des user-stories",
                linkDocument:
                    "https://drive.google.com/file/d/1u4fRuDHmDX8qhqJ8io0JbD_q-3PS2dGA/view?usp=sharing",
            },
            {
                title: "Diagrammes de cas d'usage",
                linkDocument:
                    "https://drive.google.com/file/d/1Gajd61Pc1WiFb8k91LpbX-bAr62WQFW8/view?usp=sharing",
            },
        ],
    },
    {
        title: "Débugger et tester un SasS RH",
        img: "/assets/images/projects/project9.png",
        id: 11,
        screenshot: "/assets/images/projects/project9.png",
        screenshotMobile: "/assets/images/projects/project9.png",
        description:
            "Une entreprise fictive appelée Billed, qui produit des solutions Saas destinées aux équipes de ressources humaines, développe une nouvelle application qui gère les notes de frais. Mon travail quant à moi a été de gérer la partie débogage, et la partie tests de l’application. Pour les tests de l’application, j’ai mis en place des tests unitaires, d’intégration et rédiger des tests End-to-End manuels.",
        technologies: ["HTML5", "CSS3", "JavaScript", "Jest"],
        github: "https://github.com/HugoDlhOC/HugoDelahaye_9_29032022",
        achievements: [
            "Écrire des tests unitaires avec JavaScript",
            "Débugger une application web avec le Chrome Debugger",
            "Rédiger un plan de test end-to-end manuel",
            "Écrire des tests d'intégration avec JavaScript",
        ],
        documents: undefined,
    },
    {
        title: "Développez un algorithme de recherche en Javascript",
        img: "/assets/images/projects/project7.png",
        id: 10,
        screenshot: "/assets/images/projects-preview/preview-p7.png",
        screenshotMobile: "/assets/images/projects/project7.png",
        description:
            "Une entreprise fictive appelée Les petits plats a décidé de se lancer dans un nouveau projet ; celui de créer son propre site de recettes de cuisine. Mon rôle dans ce projet a été pour commencer, d’implémenter l’interface, puis de développer la fonctionnalité de recherche du site. Ceci passe par la réflexion d’un algorithme, qui vient fonctionner pour 2 versions de la fonctionnalité : une qui utilise les boucles natives, une qui utilise la programmation fonctionnelle. J’ai eu à rédiger un document d’investigation de fonctionnalité pour comparer les 2 versions. Des tests de performance qui ont été réalisés, étaient un critère majeure pour départager quelle version était la plus performante.",
        technologies: ["HTML5", "CSS3", "JavaScript"],
        github: "https://github.com/HugoDlhOC/HugoDelahaye_7_10022022",
        demo: "https://hugodlhoc.github.io/HugoDelahaye_7_10022022/",
        achievements: [
            "Analyser un problème informatique",
            "Développer un algorithme pour résoudre un problème",
        ],
        documents: [
            {
                title: "Fiche d’investigation de fonctionnalité",
                linkDocument:
                    "https://drive.google.com/file/d/1CX-KEPDiTyVeTlwgf6Bk_WmWpwjvjv5V/view?usp=sharing",
            },
        ],
    },
    {
        title: "Créer un site accessible pour une plateforme de photographe",
        img: "/assets/images/projects/project6.png",
        id: 9,
        screenshot: "/assets/images/projects-preview/preview-p6.png",
        screenshotMobile: "/assets/images/projects/project6.png",
        description:
            "Une entreprise fictive appelée FishEye qui propose un site permettant aux photographes indépendants de présenter leurs travaux, souhaite mettre à jour leur site. J’ai dû intégrer les nouvelles maquettes et utiliser des données au format JSON pour afficher les différentes données des photographes. J’ai dû développer une « visionneuse » pour venir afficher les photos/vidéos et pouvoir naviguer entre elles. Une des priorités de cette nouvelle version du site est que ce dernier soit accessible aux personnes utilisant un lecteur d’écran. Il était donc important de veiller à ce que la navigation clavier soit fonctionnelle sur le site. ",
        technologies: ["HTML5", "CSS3", "JavaScript", "ARIA"],
        github: "https://github.com/HugoDlhOC/HugoDelahaye_6_13122021",
        demo: "https://hugodlhoc.github.io/HugoDelahaye_6_13122021/",
        achievements: [
            "Assurer l'accessibilité d'un site web",
            "Gérer les évènements d'un site avec JavaScript",
            "Développer une application web modulaire avec des design patterns",
            "Écrire du code JavaScript maintenable",
        ],
        documents: undefined,
    },
    {
        title: "Créer une landing page avec Javascript",
        img: "/assets/images/projects/project4.png",
        id: 8,
        screenshot: "/assets/images/projects-preview/preview-p4.png",
        screenshotMobile: "/assets/images/projects/project4.png",
        description:
            "Une entreprise fictive appelée GameOn, spécialisée dans les conférences et concours de jeux, souhaite renouveler sa page d’accueil qui contient un formulaire d’inscription. Grâce au JavaScript, j’ai pu développer le formulaire attendu sur des maquettes fournies, et en respectant des issues GitHub fournis. Dans les issues, nous pouvons retrouver la gestion d’un message de validation quand l’envoi est réussi, la gestion d’erreur de champs, pouvoir fermer la modale…",
        technologies: ["HTML5", "CSS3", "JavaScript"],
        github: "https://github.com/HugoDlhOC/HugoDelahaye_4_10112021",
        demo: "https://hugodlhoc.github.io/HugoDelahaye_4_10112021/",
        achievements: ["Programmer en JavaScript"],
        documents: undefined,
    },
    {
        title: "Dynamiser une page web avec des animations CSS",
        img: "/assets/images/projects/project3.png",
        id: 7,
        screenshot: "/assets/images/projects-preview/preview-p3.png",
        screenshotMobile: "/assets/images/projects/project3.png",
        description:
            "Une entreprise fictive appelée Ohmyfood! souhaite s’imposer sur le marché de la restauration. Son objectif était de développer un site 100% mobile qui affiche les menus de restaurants gastronomiques. Mon objectif a été d’intégrer les maquettes du site en HTML, CSS des différentes pages : accueil et menu. J’ai eu à développer des animations CSS plus poussées en utilisant les @keyframes. Voici un exemple des animations développées : ",
        technologies: ["HTML5", "CSS3"],
        github: "https://github.com/HugoDlhOC/HugoDelahaye_3_21102021",
        demo: "https://hugodlhoc.github.io/HugoDelahaye_3_21102021/",
        achievements: [
            "Mettre en œuvre des effets CSS graphiques avancés",
            "Assurer la cohérence graphique d'un site web",
            "Mettre en place son environnement Front-End",
            "Mettre en place une structure de navigation pour un site web",
            "Utiliser un système de gestion de versions pour le suivi du projet et son hébergement",
        ],
        documents: undefined,
    },
    {
        title: "Transformer une maquette en site web avec HTML & CSS",
        img: "/assets/images/projects/project2.png",
        id: 6,
        screenshot: "/assets/images/projects-preview/preview-p2.png",
        screenshotMobile: "/assets/images/projects/project2.png",
        description:
            "Une entreprise fictive appelée Reservia, souhaite développer un site internet pour permettre aux usagers de trouver des hébergements et des activités dans la ville de leur choix. Mon objectif était d’intégrer l’interface de la page d’accueil à partir d’une maquette Figma, et ceci avec du HTML et CSS. J’ai utilisé FlexBox et CSS Grid pour l’organisation des éléments de la page. Ce projet ne comporte que la partie graphique, c’est une simple page statique, les interactions par exemple avec le champ de recherche n’est pas fonctionnel. La page devait pouvoir s’adapter selon les appareils (smartphone, ordinateur, tablette, TV 4K…) et ceci grâce au responsive.",
        technologies: ["HTML5", "CSS3", "FlexBox", "Grid"],
        github: "https://github.com/HugoDlhOC/HugoDelahaye_2_08092021",
        demo: "https://hugodlhoc.github.io/HugoDelahaye_2_08092021/",
        achievements: [
            "Intégrer du contenu conformément à une maquette",
            "Implémenter une interface responsive",
        ],
        documents: undefined,
    },
];

export default listOfOcProjects;
