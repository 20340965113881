import { Link } from "react-router-dom";
import { projects, home, contactPage } from "../../routes/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";

export const Header = () => {
    const mainMenu = useRef();

    const openMenu = () => {
        mainMenu.current.classList.toggle("display-menu");
    };
    
    const [scrolling, setScrolling] = useState(false);
  
    useEffect(() => {
      function handleScroll() {
        if (window.scrollY > 0) {
          // L'utilisateur a commencé à défiler la page
          setScrolling(true);
        } else {
          // L'utilisateur est revenu en haut de la page
          setScrolling(false);
        }
      }
  
      // Ajoute un écouteur d'événement de défilement
      window.addEventListener("scroll", handleScroll);
  
      // Retirer l'écouteur d'événement lors du démontage du composant
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    return (
        <header style={{
            backgroundColor: scrolling ? "#1d3d60" : "#141212",
            // Ajoutez d'autres styles de votre choix
          }}>
            <nav className="navigation_header">
                <Link
                    to={home}
                    className="navigation_header__link_icon_profile link_icon_profile"
                >
                    <img
                        src={"/assets/images/avatar.png"}
                        alt="accueil"
                        className="link_icon_profile__image"
                    />
                </Link>
                <ul className={"navigation_header__main_menu main_menu"} ref={mainMenu}>
                    <li className="main_menu__item item hidden">
                        <Link to={home} className="item_link" onClick={openMenu}>
                            Accueil
                        </Link>
                    </li>
                    <li className={"main_menu__item item"}>
                        <Link to={projects} className="item_link" onClick={openMenu}>
                            Mes projets
                        </Link>
                    </li>
                    <li className={"main_menu__item item"}>
                        <a href={"https://drive.google.com/file/d/1pNtrdFCQ2PXjGPbmpzz-aWGiRJOjmPPy/view?usp=sharing"}
                            className="item_link"
                            onClick={openMenu}
                            target="_blank"
                            rel="noopener noreferrer">
                            Mon CV
                        </a>
                    </li>
                    <li id={"contact-me"}>
                        <Link to={contactPage} className="item_link" onClick={openMenu}>
                            Me contacter
                        </Link>
                    </li>
                </ul>

                {/*<ButtonTheme />*/}
                <button
                    className="navigation_header_burger_menu burger_menu"
                    onClick={openMenu}
                >
                    <FontAwesomeIcon
                        icon={faBars}
                        className="burger_menu__icon"
                        color="white"
                        size="2xl"
                    />
                </button>
            </nav>
        </header>
    );
};
