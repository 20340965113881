import Home from "./pages/Home/index";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import ThemeContextProvider, { ThemeContext } from "./context/ThemeContext";
import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import {
  home,
  projectPage,
  projects,
  errorPage,
  contactPage,
  legalTerms,
} from "./routes/routes";
import MyProjects from "./pages/MyProjects";
import Project from "./pages/Project";
import Error from "./components/Error";
import Contact from "./pages/Contact";
import ScrollToTop from "./components/ScrollToTop";
import LegalTerms from "./pages/LegalTerms";

const App = () => {
  //React Context
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    document.body.classList.remove("isDark");
    document.body.classList.remove("isLight");
    theme
      ? document.body.classList.add("isLight")
      : document.body.classList.add("isDark");
  });
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route element={<Home />} exact path={home} />
        <Route element={<MyProjects />} path={projects} />
        <Route element={<Project />} path={projectPage} />
        <Route element={<Contact />} path={contactPage} />
        <Route element={<LegalTerms />} exact path={legalTerms} />
        <Route path={errorPage} element={<Error />}></Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
