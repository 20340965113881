const listOfUdemyProjects = [
  {
    title: "Reproduction de la page d'accueil du site Bolt",
    img: "/assets/images/projects/bolt.png",
    id: 17,
    screenshot: "/assets/images/projects-preview/preview-bolt.png",
    screenshotMobile: "/assets/images/projects/bolt.png",
    description:
      "Ce projet de formation Udemy avait pour but de reproduire la page d’accueil du site Bolt avec l’outil Elementor via WordPress. Cette reproduction n’est pas identique, car des libertés étaient permises. La version desktop a été développée.",
    technologies: ["HTML5", "CSS3", "Elementor", "WordPress"],
    github: undefined,
    demo: undefined,
    // demo: "https://hugo-formation-dev.online/bolt",
    achievements: undefined,
    documents: undefined,
  },
  {
    title: "Reproduction de la page d'accueil du site Lydia",
    img: "/assets/images/projects/lydia.png",
    id: 18,
    screenshot: "/assets/images/projects-preview/preview-lydia.png",
    screenshotMobile: "/assets/images/projects/lydia.png",
    description:
      "Ce projet de formation Udemy avait pour but de reproduire la page d’accueil du site Lydia avec l’outil Elementor via WordPress. Cette reproduction n’est pas identique, car des libertés étaient permises. La version desktop a été développée.",
    technologies: ["HTML5", "CSS3", "Elementor", "WordPress"],
    github: undefined,
    demo: undefined,
    // demo: "https://hugo-formation-dev.online/",
    achievements: undefined,
    documents: undefined,
  },
];

export default listOfUdemyProjects;
