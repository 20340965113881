import { Link } from "react-router-dom";
import { legalTerms } from "../../routes/routes";

export const Footer = () => {
  return (
    <footer>
      <p>© Copyright {new Date().getFullYear()} - Hugo DELAHAYE -</p>
      <Link to={legalTerms}>Mentions légales</Link>
    </footer>
  );
};
